var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", [_vm._v("Informationen")]),
                      _c("CCardBody", [
                        _c("dl", [
                          _c("dt", [_vm._v("Id")]),
                          _c("dd", [_vm._v(_vm._s(_vm.host.id))]),
                          _c("dt", [_vm._v("Name")]),
                          _c("dd", [
                            _vm._v(
                              _vm._s(_vm.host.displayName) +
                                " (" +
                                _vm._s(_vm.host.firstName) +
                                " " +
                                _vm._s(_vm.host.lastName) +
                                ")"
                            )
                          ]),
                          _c(
                            "dt",
                            [
                              _vm._v(" Email "),
                              _c(
                                "CBadge",
                                {
                                  attrs: {
                                    color: _vm.host.emailConfirmed
                                      ? "secondary "
                                      : "danger"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.host.emailConfirmed
                                          ? "Bestätigt"
                                          : "Nicht bestätigt"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("dd", [
                            _vm._v(" " + _vm._s(_vm.host.userName) + " ")
                          ]),
                          _c("dt", [_vm._v("Letzter Login")]),
                          _c("dd", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.host.lastLoginAt.toLocaleString()) +
                                " "
                            )
                          ]),
                          _c("dt", [_vm._v("Registrierung")]),
                          _c("dd", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.host.createdAt.toLocaleString()) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "CCard",
                    [
                      _c("CCardHeader", [_vm._v("Rechnungen")]),
                      _c("CCardBody", [
                        _c("table", { staticClass: "table table-striped" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Datum")
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Betrag")
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Anzahl")
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Erstellt")
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v("Aktionen")
                              ])
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.host.hostInvoices, function(
                              hostInvoice
                            ) {
                              return _c("tr", { key: hostInvoice.id }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatIsoDateTimeTz(
                                        hostInvoice.invoiceDate
                                      )
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatCurrency(hostInvoice.amount)
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(hostInvoice.hostInvoiceLineItemCount)
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatIsoDateTimeTz(
                                        hostInvoice.createdAt
                                      )
                                    )
                                  )
                                ]),
                                _c(
                                  "td",
                                  [
                                    _vm.invoiceInUse(hostInvoice.invoiceDate)
                                      ? _c("CSpinner")
                                      : _vm._e(),
                                    !_vm.invoiceInUse(hostInvoice.invoiceDate)
                                      ? _c(
                                          "CDropdown",
                                          {
                                            attrs: {
                                              color: "primary",
                                              "toggler-text": "",
                                              "data-cy": "action-button"
                                            }
                                          },
                                          [
                                            _c(
                                              "CDropdownItem",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadInvoice(
                                                      hostInvoice.invoiceDate
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Download ")]
                                            ),
                                            _c(
                                              "CDropdownItem",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.recreateInvoice(
                                                      hostInvoice.invoiceDate
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Recreate ")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ]),
                      _c(
                        "CCardFooter",
                        [
                          _c(
                            "CButton",
                            {
                              attrs: {
                                disabled: _vm.loading,
                                color: "secondary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.recreateAllInvoices()
                                }
                              }
                            },
                            [_vm._v(" Recreate all invoices ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }